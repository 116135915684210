
export default {
  name: 'ProductCardYoutubeVideo',
  props: {
    id: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
  computed: {
    src() {
      const url = new URL(`https://www.youtube.com/embed/${this.id}`);

      const params = {
        mute: 1,
        autoplay: 1,
        controls: 0,
        rel: 0,
        loop: 1,
        playlist: this.id,
      }

      const searchParams = new URLSearchParams(url.search);

      Object.keys(params).forEach(key => searchParams.set(key, params[key]))
      url.search = searchParams.toString();

      return url.toString();
    },
  },
}
