
import { directive } from 'vue-awesome-swiper';
import ProductCard from '~/components/catalog/productCard';
import SliderArrows from '~/components/elements/SliderArrows';
import {findMaxNumber} from '~/plugins/helpers/catalog';

export default {
  components: {
    SliderArrows,
    ProductCard,
  },
  directives: {
    swiper: directive,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    titleCenter: {
      type: Boolean,
      default: true,
    },
    products: {
      type: Array,
      required: true,
    },
    sliderClass: {
      type: String,
      required: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    breakpoints: {
      type: Object,
      default() {
        return {}
      },
    },
    pagination: {
      type: String,
      default: 'none',
    },
    navigation: {
      type: String,
      default: 'block',
    },
    firstLazy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const defaultBreakpoints = {
      // when window width is >= 320px
      320: {
        spaceBetween: 0,
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      // when window width is >= 768px
      575: {
        spaceBetween: 12,
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      768: {
        spaceBetween: 12,
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      // when window width is >= 992px
      992: {
        spaceBetween: 24,
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      1200: {
        spaceBetween: 24,
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
    }

    let breakpoints = {}

    if (Object.keys(this.breakpoints).length) {
      breakpoints = this.breakpoints
    } else {
      breakpoints = defaultBreakpoints
    }

    let pagination = {}
    let navigation = {}

    if (this.pagination !== 'none') {
      const el = `.${this.sliderClass} .${this.pagination === 'fraction'
        ? 'product-slider__controls-fraction'
        : 'product-slider__dotts'}`

      pagination = { el, clickable: true, type: this.pagination }
    }

    if (this.navigation !== 'none') {
      navigation = {
        nextEl: `.${this.sliderClass} .product-slider__arrow-next`,
        prevEl: `.${this.sliderClass} .product-slider__arrow-prev`,
      }
    }

    function getMinSlides() {
      const keys = Object.keys(breakpoints).map(key => +key)

      const maxKey = findMaxNumber(keys) || 0

      if (!maxKey) return 0

      return +breakpoints[maxKey].slidesPerView
    }

    return {
      icons: {
        carrot: 'arrow-small',
      },
      minSlides: getMinSlides(),
      sliderOptions: {
        observer: true,
        observeParents: true,
        spaceBetween: 24,
        centeredSlides: false,
        freeMode: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        loop: this.loop,
        preloadImages: false,
        pagination,
        navigation,
        breakpoints,
      },
    }
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile';
    },
    isSliderPagination() {
      return this.pagination !== 'none'
    },
    isNavigationHeader() {
      return this.navigation === 'header' && this.products?.length > this.minSlides
    },
    isNavigationBlock() {
      return this.navigation === 'block' && this.products?.length > this.minSlides
    },
  },
  watch: {
    '$mq'() {
      this.updateMinSlides()
    },
  },
  beforeMount() {
    this.updateMinSlides()
  },
  methods: {
    updateMinSlides() {
      const curBreakpoint = this.getCurrentBreakpoint()

      if (curBreakpoint) {
        this.minSlides = +curBreakpoint.slidesPerView
      }
    },
    getCurrentBreakpoint() {
      switch (this.$mq) {
        case 'mobileXs':
          return this.sliderOptions.breakpoints['320']
        case 'mobile':
          return this.sliderOptions.breakpoints['575']
        case 'tablet':
          return this.sliderOptions.breakpoints['768']
        default:
          return this.sliderOptions.breakpoints['1200']
      }
    },
  },
}
